import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, typeName = '', search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        if (typeName) {
          pdfContent.push({ text: typeName, style: 'report_type', alignment: 'center' })
        }
        const allRowsHead = []
        if (search.fertilizer_name) {
          const rowItem = [
            { text: vm.$t('fertilizerConfig.fertilizer_name'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fertilizer_name_bn : search.fertilizer_name, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.from_date) {
          const rowItem = [
            { text: vm.$t('globalTrans.from_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.from_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.to_date) {
          const rowItem = [
            { text: vm.$t('globalTrans.to_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.to_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        pdfContent.push({
          table: {
            widths: ['45%', '5%', '50%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        const allRows = []
        // const newData = []
        data.forEach((ship, key) => {
          let ships = ship.ship_name
            if (i18n.locale === 'bn') {
              ships = ship.ship_name_bn
            }
          allRows.push(
            [
              { text: vm.$t('fertilizerReport.vassal_name') + ' : ' + ships, style: 'th', alignment: 'center', colSpan: 9, bold: true },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {}
            ]
          )
          ship.transport_agent.forEach((transportAgent, key1) => {
            let agent = transportAgent.agent_name
            if (i18n.locale === 'bn') {
              agent = transportAgent.agent_name_bn
            }
            allRows.push(
              [
                { text: vm.$t('fertilizerReport.carrying_agent_name') + ' : ' + agent, style: 'th', colSpan: 9, bold: true },
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {}
              ]
            )
            allRows.push(
              [
                { text: vm.$t('movement.godownName'), style: 'th', alignment: 'center' },
                { text: vm.$t('movement.loriChalanNo'), style: 'th', alignment: 'center' },
                { text: vm.$t('movement.deliveredAmount'), style: 'th', alignment: 'center' },
                { text: vm.$t('movement.issue_date'), style: 'th', alignment: 'center' },
                { text: vm.$t('movement.receivedDate'), style: 'th', alignment: 'center' },
                { text: vm.$t('movement.duration'), style: 'th', alignment: 'center' },
                { text: vm.$t('movement.receivedAmount'), style: 'th', alignment: 'right' },
                { text: vm.$t('movement.difference_amount'), style: 'th', alignment: 'right' },
                { text: vm.$t('globalTrans.status'), style: 'th', alignment: 'center' }
              ]
            )
            transportAgent.godowns.forEach((godown) => {
              godown.list.forEach((list, index1) => {
                const NewLoop = []
                if (index1 === 0) {
                  NewLoop.push({ text: (i18n.locale === 'bn') ? godown.godown_name_bn : godown.godown_name, style: 'td', rowSpan: godown.list.length, alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                NewLoop.push(
                  { text: list.lori_challan_no, style: 'td', alignment: 'center' },
                  { text: vm.$n(list.total_allocated_amount, { useGrouping: false }), style: 'td', alignment: 'right' },
                  { text: dateFormat(list.allocation_date), style: 'td', alignment: 'right' }
                )
                if (list.receive_date) {
                  NewLoop.push({ text: dateFormat(list.receive_date), style: 'td', alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                if (list.duration > 0) {
                  NewLoop.push({ text: vm.$n(list.duration, { useGrouping: false }), style: 'td', alignment: 'center' })
                } else {
                  NewLoop.push({ text: list.duration, style: 'td', alignment: 'center' })
                }
                if (list.received_amount) {
                  NewLoop.push({ text: vm.$n(list.received_amount, { useGrouping: false }), style: 'td', alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                if (list.received_amount) {
                  NewLoop.push({ text: (list.total_allocated_amount > list.received_amount ? '(-)' : '') + vm.$n((list.total_allocated_amount - list.received_amount), { useGrouping: false }), style: 'td', alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                if (list.receive_date) {
                  NewLoop.push({ text: vm.$t('globalTrans.receive'), style: 'td', alignment: 'center' })
                } else {
                  NewLoop.push({ text: vm.$t('globalTrans.not_receive'), style: 'td', alignment: 'center' })
                }
                allRows.push(NewLoop)
              })
            })
          })
        })
        pdfContent.push({
          table: {
            widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 14,
              margin: [0, 10, 0, 20]
            },
            header3: {
              fontSize: 9,
              margin: [0, 0, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            },
            krishi: {
              margin: [0, -5, 0, 15],
              alignment: 'center'
            },
            report_type: {
              fontSize: 9,
              margin: [0, 2, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('fertilizer-delivery-receive-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
