<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.fertilizer_delivery_receive_report') }} {{ $t('globalTrans.search') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Fertilizer"  vid="fertilizer_id"  rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="fertilizer_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('fertilizerConfig.fertilizer_name')}}  <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.fertilizer_id"
                      :options="fertilizerNameList"
                      id="fertilizer_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="From Date"  vid="date_from" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="from_date"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.from_date')}}  <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      class="fromDate"
                      v-model="search.from_date"
                      :placeholder="$t('globalTrans.select_date')"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="To Date"  vid="to_date" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="to_date"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                        {{$t('globalTrans.to_date')}}  <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      class="toDate"
                        v-model="search.to_date"
                      :placeholder="$t('globalTrans.select_date')"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
        </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('fertilizerReport.fertilizer_delivery_receive_report') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" @click="pdfExport">
              <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay>
              <div style="border: 2px solid;margin:10px;">
                <b-row>
                  <b-col>
                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                      {{ $t('fertilizerReport.fertilizer_delivery_receive_report') }}
                    </list-report-head>
                  </b-col>
                </b-row>
                <div class="text-center">
                  <table style="width:100%;color:black;">
                    <tr v-if="search.fertilizer_id">
                      <td align="right" style="width:40%">{{$t('fertilizerConfig.fertilizer_name')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">
                        {{ ($i18n.locale==='bn') ? search.fertilizer_name_bn : search.fertilizer_name }}
                      </td>
                    </tr>
                    <tr v-if="search.from_date">
                      <td align="right" style="width:40%">{{$t('globalTrans.from_date')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">
                        {{ search.from_date | dateFormat }}
                      </td>
                    </tr>
                    <tr v-if="search.to_date">
                      <td align="right" style="width:40%">{{$t('globalTrans.to_date')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">
                        {{ search.to_date | dateFormat }}
                      </td>
                    </tr>
                  </table>
                </div>
                <b-col md="12" class="table-responsive">
                  <b-overlay>
                    <slot v-if="report.length > 0">
                      <b-col md="12">
                        <br/>
                        <b-table-simple class="mt-2" bordered hover small caption-top responsive :key="'details' + key + key1">
                          <template v-for="(ship, key) in report">
                              <b-thead :key="'first' + key + key1">
                                <b-tr>
                                    <b-th colspan="9" class="text-center">
                                      {{ $t('fertilizerReport.vassal_name') }} :
                                      {{ ($i18n.locale === 'bn') ? ship.ship_name_bn : ship.ship_name }}
                                    </b-th>
                                </b-tr>
                              </b-thead>
                              <template v-for="(transport_agent, key1) in ship.transport_agent">
                                <b-thead :key="'top' + key + key1">
                                  <b-tr>
                                      <b-th colspan="9">
                                        {{ $t('fertilizerReport.carrying_agent_name') }} :
                                        {{ ($i18n.locale === 'bn') ? transport_agent.agent_name_bn : transport_agent.agent_name }}
                                      </b-th>
                                  </b-tr>
                                </b-thead>
                                <b-thead :key="'bottom' + key + key1">
                                  <b-tr>
                                    <b-th class="text-center">{{ $t('movement.godownName') }}</b-th>
                                    <b-th class="text-center">{{ $t('movement.loriChalanNo') }}</b-th>
                                    <b-th class="text-right">{{ $t('movement.deliveredAmount') }}</b-th>
                                    <b-th class="text-center">{{ $t('movement.issue_date') }}</b-th>
                                    <b-th class="text-center">{{ $t('movement.receivedDate') }}</b-th>
                                    <b-th class="text-right">{{ $t('movement.duration') }}</b-th>
                                    <b-th class="text-right">{{ $t('movement.receivedAmount') }}</b-th>
                                    <b-th class="text-right">{{ $t('movement.difference_amount') }}</b-th>
                                    <b-th class="text-center">{{ $t('globalTrans.status') }}</b-th>
                                  </b-tr>
                                </b-thead>
                                <tbody :key="'body' + key + key1">
                                  <template v-for="godown in transport_agent.godowns">
                                    <tr v-for="(list, index1) in godown.list" :key="key + key1+ list.id">
                                      <slot v-if="index1 == 0">
                                        <td class="text-center align-middle" :rowspan="godown.list.length">
                                          {{ ($i18n.locale === 'bn') ? godown.godown_name_bn : godown.godown_name }}
                                        </td>
                                      </slot>
                                      <td class="text-center">
                                        {{ list.lori_challan_no }}
                                      </td>
                                      <td class="text-right">
                                        {{ $n(list.total_allocated_amount, { useGrouping: false }) }}
                                      </td>
                                      <td class="text-center">
                                        {{ list.allocation_date | dateFormat }}
                                      </td>
                                      <td class="text-center">
                                        <slot v-if="list.receive_date">
                                          {{ list.receive_date | dateFormat }}
                                        </slot>
                                      </td>
                                      <td class="text-right">
                                        <span class="badge badge-danger" v-if="parseInt(list.duration) > 2">
                                          {{$n(list.duration, { useGrouping: false })}}
                                        </span>
                                        <span class="badge badge-success" v-else>
                                          {{$n(list.duration, { useGrouping: false })}}
                                        </span>
                                      </td>
                                      <td class="text-right">
                                        <slot v-if="list.received_amount">
                                          {{ $n(list.received_amount, { useGrouping: false }) }}
                                        </slot>
                                      </td>
                                      <td class="text-right">
                                        <slot v-if="list.received_amount">
                                          {{ (list.total_allocated_amount > list.received_amount ? '(-)' : '') + $n((list.total_allocated_amount-list.received_amount), { useGrouping: false }) }}
                                        </slot>
                                      </td>
                                      <td class="text-center">
                                        <span class="badge badge-success" v-if="list.receive_date">
                                          {{ $t('globalTrans.receive') }}
                                        </span>
                                        <span class="badge badge-warning" v-else>
                                          {{ $t('globalTrans.not_receive') }}
                                        </span>
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </template>
                          </template>
                        </b-table-simple>
                      </b-col>
                    </slot>
                    <slot v-else>
                      <br/>
                      <h4 class="text-danger text-center">{{ $t('globalTrans.noDataFound') }}</h4>
                      <br/>
                    </slot>
                  </b-overlay>
                </b-col>
              </div>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, fertilizerDeliveryReceiveReport } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import ListReportHead from '.././ListReportHead.vue'
import flatpickr from 'flatpickr'

const today = new Date().toISOString().substr(0, 10)

export default {
  name: 'UiDataTable',
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      showData: false,
      search: {
        fertilizer_id: 0,
        fertilizer_name_bn: '',
        fertilizer_name: '',
        from_date: '',
        to_date: ''
      },
      report: [],
      listData: [],
      today: today,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    WarehouseUserList: function () {
      const listObject = this.warehouseUserData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    regionList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    formTitle () {
       return this.$t('fertilizerReport.fertilizer_delivery_receive_report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    // 'search.division_id': function (newVal, oldVal) {
    //   this.districtList = this.getDistrictList(newVal)
    // },
    // 'search.region_id': function (newVal, oldVal) {
    //   this.districtList = this.getRegionDistrictList(newVal)
    // },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
    flatpickr('.toDate', {})
  },
  methods: {
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      const fertInfo = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(fertilizerNameList => fertilizerNameList.value === this.search.fertilizer_id)
      this.search.fertilizer_name = fertInfo !== undefined ? fertInfo.text_en : ''
      this.search.fertilizer_name_bn = fertInfo !== undefined ? fertInfo.text_bn : ''
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, fertilizerDeliveryReceiveReport, params).then(response => {
        if (response.success) {
          if (response.datas.length > 0) {
            this.listData = response.listData
            this.report = this.getRelationalData(response.datas)
          }
        } else {
          this.report = []
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const transportAgent = this.$store.state.SeedsFertilizer.commonObj.transportAgentList
      const godownInfo = this.$store.state.SeedsFertilizer.commonObj.godownInfoList
      const allDatas = []
      data.forEach((ship, shipkey) => {
        const agentInfo = this.listData.find(listData => listData.ship_name === ship.ship_name)
        ship.ship_name_bn = agentInfo !== undefined ? agentInfo.ship_name_bn : ''
        ship.transport_agent.forEach((agent, agentkey) => {
          const transportAgentList = transportAgent.find(transportAgentList => transportAgentList.value === agent.transport_agent_id)
          agent.agent_name = transportAgentList !== undefined ? transportAgentList.text_en : ''
          agent.agent_name_bn = transportAgentList !== undefined ? transportAgentList.text_bn : ''
          agent.godowns.forEach((element, key) => {
            const godownInfoList = godownInfo.find(godownInfoList => godownInfoList.value === element.godown_infos_id)
            element.godown_name = godownInfoList !== undefined ? godownInfoList.text_en : ''
            element.godown_name_bn = godownInfoList !== undefined ? godownInfoList.text_bn : ''
            element.list.forEach((element1, key1) => {
              if (element1.receive_date) {
                element1.duration = this.getDateDifference(element1.allocation_date, element1.receive_date)
              } else {
                element1.duration = this.getDateDifference(element1.allocation_date, today)
              }
            })
          })
        })
        allDatas[shipkey] = ship
      })
      return allDatas
    },
    getDateDifference (a, b) {
      const date1 = new Date(a)
      const date2 = new Date(b)
      const diffTime = Math.abs(date2 - date1)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    },
    pdfExport () {
      const reportTypeTitle = ''
      const reportTitle = this.$t('fertilizerReport.fertilizer_delivery_receive_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.report, this, reportTypeTitle, this.search)
    },
    getPdfData () {
        const delivery = [
            { text: this.$i18n.locale === 'bn' ? this.warehouseName_bn : this.warehouseName },
            { text: this.$n(this.allocationData[0].tot_complain) },
            { text: this.$n(this.allocationData[0].total_resolve) },
            { text: this.$n(this.allocationData[0].ratio) }
          ]
        return delivery
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
